const region = localStorage.getItem('region');

export const serverUrl = `${process.env.REACT_APP_HTTPS === 'true' ? 'https' : 'http'}://${
  region === 'us' ? process.env.REACT_APP_SERVER_URL_US : process.env.REACT_APP_SERVER_URL
}`;

const regionAgnosticServerUrl = `${process.env.REACT_APP_HTTPS === 'true' ? 'https' : 'http'}://${
  process.env.REACT_APP_SERVER_URL_REGION_AGNOSTIC
}`;

export const isInternalMeshServer = Boolean(process.env.REACT_APP_IS_INTERNAL_MESH_SERVER === 'true');

export const getWebSocketUrl = () => {
  // return `${websocketHostURLPrefix}/client/${token}`;
};

export const endpoints = {
  mediaUpload: `${serverUrl}/service/custom/auth/mediaUpload`,
  demo: {
    demoUserLogin: `${serverUrl}/service/custom/auth/login/admin/public-demo/authenticate`,
  },
  employee: {
    profileImageUpload: (employeeId) => `${serverUrl}/service/custom/auth/employee/${employeeId}/photo`,
    deleteProfileImage: (employeeId) => `${serverUrl}/service/custom/auth/employee/${employeeId}/`,
    checkIfEmployeeIsManagerOfManager: (loggedInEmployeeId) =>
      `${serverUrl}/service/custom/auth/employee/${loggedInEmployeeId}/isManagerOfManager`,
    details: (employeeId) => `${serverUrl}/service/custom/auth/employee/${employeeId}`,
    remove: (employeeId) => `${serverUrl}/service/custom/auth/employee/${employeeId}`,
    nodes: (employeeId) => `${serverUrl}/service/custom/auth/employee/${employeeId}/nodes`,
    // relationships: `${host}/service/custom/auth/employee/relationships`,
    login: `${serverUrl}/service/system/authenticate`,
    logout: `${serverUrl}/service/system/logout`,
    redirection_logout: `${serverUrl}/service/custom/auth/login/logout`,
    leaderboard: (trophyId) => `${serverUrl}/service/custom/auth/leaderboard/trophies/${trophyId}`,
    nominate: `${serverUrl}/service/custom/auth/leaderboard/action`,
    leaderboardTrophies: `${serverUrl}/service/custom/auth/leaderboard/trophies`,
    meshTrophies: (employeeId) => `${serverUrl}/service/custom/auth/employee/${employeeId}/trophies`,
    transactions: (employeeId) => `${serverUrl}/service/custom/auth/employee/${employeeId}/transactions`,
    reliabilityScore: (employeeId) => `${serverUrl}/service/custom/auth/employee/${employeeId}/reliabilityScore`,
    teams: (employeeId) => `${serverUrl}/service/custom/auth/employee/${employeeId}/teams`,
    create: `${serverUrl}/service/custom/auth/employee/save`,
    search: `${serverUrl}/service/custom/auth/search/employee`,
    timezone: (employeeId) => `${serverUrl}/service/custom/auth/employee/${employeeId}/timezone`,
  },
  moolah: {
    // get: `${host}/service/custom/auth/moolah/get`
  },
  notifications() {
    const index = `${serverUrl}/service/custom/auth/notifications`;
    const action = `${index}/action`;
    return { index, action };
  },
  helloBar: `${serverUrl}/service/custom/auth/notifications/helloBar`,
  meshWin: {
    // get: employeeId => `${host}/service/custom/auth/meshWin/${employeeId}/get`
  },
  search: {
    // recentList: `${host}/service/custom/auth/search/recent`,
    // frequentList: `${host}/service/custom/auth/search/frequent`,
    // tagsList: `${host}/service/custom/auth/search/tags`,
    // submit: `${host}/service/custom/auth/search/employee`,
    autoComplete: `${serverUrl}/service/custom/auth/search/autocomplete`,
    autoCompleteV1: `${serverUrl}/service/custom/auth/search/autocomplete/v1`,
  },
  tasks: {
    index: `${serverUrl}/service/custom/auth/tasks`,
    checkIn: (taskId) => `${serverUrl}/service/custom/auth/tasks/${taskId}/checkIn`,
    count: `${serverUrl}/service/custom/auth/tasks/count`,
    detail: (taskId) => `${serverUrl}/service/custom/auth/tasks/${taskId}`,
    discussion: (taskId) => `${serverUrl}/service/custom/auth/feed/task/${taskId}/discussion`,
    indexV1: `${serverUrl}/service/custom/auth/tasks/v1`,
    indexCards: `${serverUrl}/service/custom/auth/tasks/cards`,
    stats: `${serverUrl}/service/custom/auth/tasks/stats`,
    statsV1: `${serverUrl}/service/custom/auth/tasks/statsV1`,
    team: {
      performance: (teamId) => `${serverUrl}/service/custom/auth/tasks/team/${teamId}/reliabilityScore`,
    },
    recurrent: {
      get: (parentTaskId) => `${serverUrl}/service/custom/auth/tasks/recurrentTask/${parentTaskId}`,
      stats: (parentTaskId) => `${serverUrl}/service/custom/auth/tasks/recurrentTask/${parentTaskId}/stats`,
    },
    comments: (taskId) => `${serverUrl}/service/custom/auth/tasks/${taskId}/activity/comments`,
    goalTasks: (goalId) => `${serverUrl}/service/custom/auth/tasks/${goalId}/goal`,
    subTasks: (taskId) => `${serverUrl}/service/custom/auth/tasks/${taskId}/task`,
    progressGraph: (goalId) => `${serverUrl}/service/custom/auth/goals/${goalId}/progressGraph`,
    refreshMetric: (goalId) => `${serverUrl}/service/custom/auth/tasks/refreshMetric/${goalId}`,
    timelineList: `${serverUrl}/service/custom/auth/tasks/weightage/timelineList`,
    weightage: `${serverUrl}/service/custom/auth/tasks/weightage`,
    objectiveHeirarchy: `${serverUrl}/service/custom/auth/tag/organization/objectivesHierarchy`,
  },
  objective: {
    objectiveHierarchy: `${serverUrl}/service/custom/auth/okr/objectivesHierarchy`,
    timePeriodObjectives: `${serverUrl}/service/custom/auth/okr/objective`,
    getObjective: `${serverUrl}/service/custom/auth/okr/objectiveFromId`,
    saveObjective: `${serverUrl}/service/custom/auth/okr/objective`,
    realignObjective: `${serverUrl}/service/custom/auth/okr/objective/realign`,
    deleteObjective: (objectiveId) => `${serverUrl}/service/custom/auth/okr/objective/${objectiveId}`,
    cloneObjective: `${serverUrl}/service/custom/auth/okr/objective/copy`,
    childObjectives: `${serverUrl}/service/custom/auth/okr/childObjectives`,
    parentLevelVisibleForDeleteRealign: (objectiveTimeBoundId) =>
      `${serverUrl}/service/custom/auth/okr/objectiveTimeBound/${objectiveTimeBoundId}/krExists`,
  },
  agenda: {
    create: `${serverUrl}/service/custom/auth/checkIn/agenda/v1`,
    checkIn: (checkInId) => `${serverUrl}/service/custom/auth/checkIn/${checkInId}/agenda`,
    update: (agendaId) => `${serverUrl}/service/custom/auth/checkIn/agenda/${agendaId}`,
    delete: (agendaId) => `${serverUrl}/service/custom/auth/checkIn/agendaItem/${agendaId}`,
    addToAgenda: `${serverUrl}/service/custom/auth/checkIn/addToAgenda/v1`,
  },
  workZero: {
    nudgeCards: `${serverUrl}/service/custom/auth/nudge/nudgeCardsV2`,
    snoozeNudgeCard: `${serverUrl}/service/custom/auth/nudge/snoozeNudgeCard`,
    trophyDetails: `${serverUrl}/service/custom/auth/trophyDetails`,
    nudgeCardsV3: `${serverUrl}/service/custom/auth/nudge/nudgeCardsV3`,
    snoozeNudgeCardV3: `${serverUrl}/service/custom/auth/nudge/snooze`,
  },
  feed: {
    // meshFeed: employeeId => `${host}/service/custom/auth/employee/${employeeId}/feed/mesh`,
    myFeed: `${serverUrl}/service/custom/auth/feed`,
    reaction: (feedId) => `${serverUrl}/service/custom/auth/feed/${feedId}/reaction`,
    detail: (feedId) => `${serverUrl}/service/custom/auth/feed/${feedId}`,
  },
  home: {
    mySummary: {
      reviews: `${serverUrl}/service/custom/auth/review/summary/reviewForms`,
      feedback: (employeeId) => `${serverUrl}/service/custom/auth/feedback/profileSummary/${employeeId}`,
      competency: (employeeId) => `${serverUrl}/service/custom/auth/competency/profileSummary/${employeeId}`,
      goals: `${serverUrl}/service/custom/auth/tasks/v2`,
      ooo: `${serverUrl}/service/custom/auth/ooo/oooDetails`,
    },
  },
  analytics: {
    review: {
      // TODO: move aggregatedCount, tabularData inside status.
      cohort: {
        values: `${serverUrl}/service/custom/auth/cohort/validKeyValue`,
        save: `${serverUrl}/service/custom/auth/cohort`,
      },
      filters: `${serverUrl}/service/custom/auth/analytics/leaders/status/filters`,
      filtersV1: `${serverUrl}/service/custom/auth/analytics/leaders/status/filters`,
      reviewCyclesAndCalibrationViews: `${serverUrl}/service/custom/auth/review/summary/list/cycles`,
      // count: `${serverUrl}/service/custom/auth/analytics/leaders/status/count`,
      aggregatedCount: `${serverUrl}/service/custom/auth/analytics/leaders/status/aggregatedScore`,
      // aggregatedCount: `${serverUrl}/service/custom/auth/analytics/leaders/review/aggregatedScore`,
      tabularData: `${serverUrl}/service/custom/auth/analytics/leaders/status/tabular`,
      generateFile: `${serverUrl}/service/custom/auth/analytics/leaders/status/tabular/report/generate`,
      fileGenerationStatus: `${serverUrl}/service/custom/auth/report/status/`,
      reviewCycleStage: `${serverUrl}/service/custom/auth/review/summary/reviewCycle/stage`,
      downloadFile: (requestId) => `${serverUrl}/service/custom/auth/report/download/${requestId}`,
      calibration: {
        tabularData: `${serverUrl}/service/custom/auth/analytics/leaders/calibration/tabular`,

        generateFile: `${serverUrl}/service/custom/auth/analytics/leaders/calibration/tabular/report/generate`,
        aggregateCount: `${serverUrl}/service/custom/auth/analytics/leaders/calibration/aggregatedScore`,
        performanceGrid: `${serverUrl}/service/custom/auth/analytics/leaders/calibration/performanceGrid/v1`,
        recalibrate: (employeeId) =>
          `${serverUrl}/service/custom/auth/analytics/leaders/calibration/recalibrate/${employeeId}`,
      },
      generateReviewSummaryPDF: `${serverUrl}/service/custom/auth/review/generateReport`,
      performanceReviewGraph: `${serverUrl}/service/custom/auth/review/performanceGraph`,
      trendLineChart: `${serverUrl}/service/custom/auth/analytics/leaders/calibration/trendlineGraph`,
      partialPublish: {
        publish: `${serverUrl}/service/custom/auth/analytics/leaders/review/publish`,
        options: `${serverUrl}/service/custom/auth/analytics/leaders/review/publish/options`,
      },
      bulkNotify: {
        notify: `${serverUrl}/service/custom/auth/analytics/leaders/calibration/notify`,
        options: `${serverUrl}/service/custom/auth/analytics/leaders/calibration/notify/options`,
      },
      sankeyChart: `${serverUrl}/service/custom/auth/analytics/leaders/calibration/sankeyGraph/compare`,
    },
    goal: {
      filters: `${serverUrl}/service/custom/auth/analytics/leaders/goal/filters`,
      filtersV1: `${serverUrl}/service/custom/auth/analytics/leaders/goal/filters`,
      cohort: {
        values: `${serverUrl}/service/custom/auth/cohort/validKeyValue`,
        save: `${serverUrl}/service/custom/auth/cohort`,
      },
      cascadedView: `${serverUrl}/service/custom/auth/analytics/leaders/goal/cascadedView`,
      realignView: `${serverUrl}/service/custom/auth/analytics/leaders/goal/realignView`,
      objective: {
        tabularData: `${serverUrl}/service/custom/auth/analytics/leaders/goal/tabular`,
        tabularDataV1: `${serverUrl}/service/custom/auth/analytics/leaders/goal/tabular/v1`,
        // aggregatedScore: `${serverUrl}/service/custom/auth/analytics/leaders/goal/aggregatedScore`,
        topGoals: `${serverUrl}/service/custom/auth/analytics/leaders/goal/topGoals`,
        goals: `${serverUrl}/service/custom/auth/analytics/leaders/goal/goals`,
        status: `${serverUrl}/service/custom/auth/analytics/leaders/goal/status`,
        timeline: `${serverUrl}/service/custom/auth/analytics/leaders/goal/timeline`,
      },
      generateFile: `${serverUrl}/service/custom/auth/analytics/leaders/goal/tabular/report/generate`,
      groupByIndividual: `${serverUrl}/service/custom/auth/analytics/leaders/goal/groupByIndividual`,
      // groupByIndividualOld: `${serverUrl}/service/custom/auth/analytics/leaders/goal/okrInsightsByIndividual`,
      groupByGoals: `${serverUrl}/service/custom/auth/analytics/leaders/goal/groupByGoal`,
    },
    initiative: {
      list: `${serverUrl}/service/custom/auth/initiative/list`,
      myInitiatives: `${serverUrl}/service/custom/auth/initiative/myInitiatives`,
      index: `${serverUrl}/service/custom/auth/initiative`,
      OKRInitiatives: (employeeId) => `${serverUrl}/service/custom/auth/goals/${employeeId}/initiatives`,
      specificInitative: (employeeId) => `${serverUrl}/service/custom/auth/initiative/${employeeId}`,
      updateInitiativeProgress: (taskId) => `${serverUrl}/service/custom/auth/initiative/${taskId}/progress`,
      childInitiatives: (initiativeId) =>
        `${serverUrl}/service/custom/auth/initiative/${initiativeId}/child-initiatives`,
    },
    oneOnOne: {
      filters: `${serverUrl}/service/custom/auth/analytics/leaders/oneOnOne/filters`,
      filtersV1: `${serverUrl}/service/custom/auth/analytics/leaders/oneOnOne/filters`,
      cohort: {
        values: `${serverUrl}/service/custom/auth/cohort/validKeyValue`,
        save: `${serverUrl}/service/custom/auth/cohort`,
      },
      tabularData: `${serverUrl}/service/custom/auth/analytics/leaders/oneOnOne/tabular`,
      aggregatedScore: `${serverUrl}/service/custom/auth/analytics/leaders/oneOnOne/aggregatedScore`,
      topManagers: `${serverUrl}/service/custom/auth/analytics/leaders/oneOnOne/topEmployees`,
      generateFile: `${serverUrl}/service/custom/auth/analytics/leaders/oneOnOne/tabular/report/generate`,
    },
    feedback: {
      filters: `${serverUrl}/service/custom/auth/analytics/leaders/competency/filters`,
      filtersV1: `${serverUrl}/service/custom/auth/analytics/leaders/competency/filters`,
      tabularData: `${serverUrl}/service/custom/auth/analytics/leaders/competency/tabular`,
      topNResult: `${serverUrl}/service/custom/auth/analytics/leaders/competency/topNResult`, // @deprecated
      aggregatedScore: `${serverUrl}/service/custom/auth/analytics/leaders/competency/aggregatedScore`,
      timeline: `${serverUrl}/service/custom/auth/analytics/leaders/competency/timeline`,
      generateFile: `${serverUrl}/service/custom/auth/analytics/leaders/competency/tabular/report/generate`,
    },
    engagement: {
      filters: `${serverUrl}/service/custom/auth/analytics/leaders/engagement/filters`,
      filtersV1: `${serverUrl}/service/custom/auth/analytics/leaders/engagement/filters`,
      tabularData: `${serverUrl}/service/custom/auth/analytics/leaders/engagement/tabular`,
      expandedTabularData: `${serverUrl}/service/custom/auth/analytics/leaders/engagement/tabular/expand`,
      topNResult: `${serverUrl}/service/custom/auth/analytics/leaders/engagement/topNResult`, // @deprecated
      aggregatedScore: `${serverUrl}/service/custom/auth/analytics/leaders/engagement/aggregatedScore`,
      timeline: `${serverUrl}/service/custom/auth/analytics/leaders/engagement/timeline`,
      generateFile: `${serverUrl}/service/custom/auth/analytics/leaders/engagement/tabular/report/generate`,
      engagementCount: `${serverUrl}/service/custom/auth/engagement/survey/count`,
    },
    levelUp: {
      filters: `${serverUrl}/service/custom/auth/analytics/leaders/levelUp/filters`,
      filtersV1: `${serverUrl}/service/custom/auth/analytics/leaders/levelUp/filters`,
      tabularData: `${serverUrl}/service/custom/auth/analytics/leaders/levelUp/tabular/v1`,
    },
    teamSummary: `${serverUrl}/service/custom/auth/analytics/teamSummary`,
    continuousFeedback: {
      tabularData: `${serverUrl}/service/custom/auth/analytics/leaders/continuousFeedback/tabular`,
      filters: `${serverUrl}/service/custom/auth/analytics/leaders/continuousFeedback/filters`,
      filtersV1: `${serverUrl}/service/custom/auth/analytics/leaders/continuousFeedback/filters`,
      generateFile: `${serverUrl}/service/custom/auth/analytics/leaders/continuousFeedback/tabular/report/generate`,
      barGraphData: `${serverUrl}/service/custom/auth/analytics/leaders/continuousFeedback/timeline`,
      timelineGraph: `${serverUrl}/service/custom/auth/feedback/timeline/graph`,
    },
    // developProgress: `${host}/service/custom/auth/analytics/develop/progress`,
    // deliverProgress: `${host}/service/custom/auth/analytics/deliver/progress`,
    // performance: `${host}/service/custom/auth/analytics/performance`,
    // teamPerformance: `${host}/service/custom/auth/analytics/managers/performance`,
    // network: `${host}/service/custom/auth/analytics/network`,
    // phraseSourceComments: `${host}/service/custom/auth/analytics/drive/phraseSourceComments`,
    // wordCloud: `${host}/service/custom/auth/analytics/drive/wordcloud`
  },
  developments: {
    list: (employeeId) => `${serverUrl}/service/custom/auth/employee/${employeeId}/tags`,
    listV1: (employeeId) => `${serverUrl}/service/custom/auth/employee/${employeeId}/tags/v1`,
    detail: (employeeId, employeeMappingId) =>
      `${serverUrl}/service/custom/auth/employee/${employeeId}/tags/${employeeMappingId || 'miscellaneous'}`,
    // progress: employeeId =>
    // `${host}/service/custom/auth/employee/${employeeId}/developments/progress`,
    feedback: (employeeId, tagId) =>
      `${serverUrl}/service/custom/auth/employee/${employeeId}/tags/${tagId ? `${tagId}/` : ''}posts`,
    feedbackV1: (employeeId, tagId) =>
      `${serverUrl}/service/custom/auth/employee/${employeeId}/tags/${tagId ? `${tagId}/` : ''}posts/v1`,
    allFeedback: `${serverUrl}/service/custom/auth/employee/feedback`,
    miscellaneousCompetencyFeedback: (employeeId) =>
      `${serverUrl}/service/custom/auth/employee/${employeeId}/tags/posts/v1`,
    competencyStats: (employeeId, competencyId) =>
      `${serverUrl}/service/custom/auth/employee/${employeeId}/tags/${competencyId}/stats`,
    allStats: `${serverUrl}/service/custom/auth/employee/feedback/stats`,
  },
  deliverables: {
    // active: employeeId => `${host}/service/custom/auth/employee/${employeeId}/deliverables/active`,
    // archived: employeeId =>
    //   `${host}/service/custom/auth/employee/${employeeId}/deliverables/archived`,
    // progress: employeeId =>
    //   `${host}/service/custom/auth/employee/${employeeId}/deliverables/progress`,
    // index: (employeeId, deliverableId) =>
    //   `${host}/service/custom/auth/employee/${employeeId}/deliverables/${deliverableId}`
  },
  drivers: {
    // satisfaction: employeeId =>
    //   `${host}/service/custom/auth/employee/${employeeId}/drivers/satisfaction`,
    // list: employeeId => `${host}/service/custom/auth/employee/${employeeId}/drivers/`
  },
  post: {
    attributePrediction: `${serverUrl}/service/custom/auth/prediction/postAttributes`,
    visibilityPrediction: `${serverUrl}/service/custom/auth/prediction/visibility`,
    create: `${serverUrl}/service/custom/auth/post`,
    edit: (postId) => `${serverUrl}/service/custom/auth/post/${postId}`,
    comments: {
      create: (feedId) => `${serverUrl}/service/custom/auth/feed/${feedId}/comment`,
      edit: (feedId, commentId) => `${serverUrl}/service/custom/auth/feed/${feedId}/comment/${commentId}`,
      get: (feedId) => `${serverUrl}/service/custom/auth/feed/${feedId}/comment`,
    },
  },
  managers: {
    team: {
      employeeTagComparison: `${serverUrl}/service/custom/auth/analytics/employeeTagComparison`,
      employeeTagPerformance: `${serverUrl}/service/custom/auth/analytics/employeePerformance`,
      cohortTeamEmployees: `${serverUrl}/service/custom/auth/cohort/employees`,
    },
  },
  store: {
    vouchers: {
      index: (employeeId) => `${serverUrl}/service/custom/auth/employee/${employeeId}/vouchers`,
      detail: (employeeId, voucherId) =>
        `${serverUrl}/service/custom/auth/employee/${employeeId}/vouchers/${voucherId}`,
    },
  },
  // admin
  admin: {
    organization: (orgId) => `${serverUrl}/service/custom/auth/organization/${orgId}`,
    mediaUpload: `${serverUrl}/service/custom/auth/mediaUpload`,
    meshTag: `${serverUrl}/service/custom/auth/admin/tag/mesh`,
    organizationTag: `${serverUrl}/service/custom/auth/tag/organization`,
    competencies: `${serverUrl}/service/custom/auth/competency/list`,
    saveCompetency: `${serverUrl}/service/custom/auth/competency/save`,
    roleBasedCompetency: `${serverUrl}/service/custom/auth/tag/organization/roleBasedCompetency`,
    competencyId: (competencyId) => `${serverUrl}/service/custom/auth/competency/${competencyId}/update`,
    employeeCompetencies: `${serverUrl}/service/custom/auth/tag/organization/competencies/employee`,
    applicableEmployeeCompetencies: `${serverUrl}/service/custom/auth/tag/organization/competencies/employees/applicable`,
    functions: `${serverUrl}/service/custom/auth/functionTree`,
    managersList: `${serverUrl}/service/custom/auth/cohort/manager/list`,
    grades: `${serverUrl}/service/custom/auth/functionTree/grades`,
    reorderGrades: `${serverUrl}/service/custom/auth/functionTree/grades/reorder`,
    referralCode: `${serverUrl}/service/custom/auth/referralCode/compute`,
    shareReferralCode: `${serverUrl}/service/custom/auth/referralCode/share`,
    bulkUploadEmployees: `${serverUrl}/service/custom/auth/employee/bulkUpload`,
    bulkUploadConfig: `${serverUrl}/service/custom/auth/uploadConfig`,
    bulkUploadEmployeesStatus: `${serverUrl}/service/custom/auth/upload/status`,
    downloadBulkUploadEmployees: (id) => `${serverUrl}/service/custom/auth/upload/download_file/${id}`,
    bulkUploadWrapper: `${serverUrl}/service/custom/auth/uploadConfig`,
    orgModulesSettings: `${serverUrl}/service/custom/auth/organization/modules`,
    timeBoundObjective: {
      objectiveSettings: `${serverUrl}/service/custom/auth/organization/settings/objective`,
      objectiveDisplaySettings: `${serverUrl}/service/custom/auth/organization/settings/objective/display`,
      timeBoundList: `${serverUrl}/service/custom/auth/tag/organization/timeBoundDetails`,
      timePeriodObjectives: `${serverUrl}/service/custom/auth/tag/organization/objectiveTimeBound`,
      updateObjective: (timeBoundObjectiveId) =>
        `${serverUrl}/service/custom/auth/tag/organization/objectiveTimeBound/${timeBoundObjectiveId}`,
      objectiveTimeline: `${serverUrl}/service/custom/auth/tag/organization/objectiveTimeBound/timeline`,
      importTimePeriodObjectives: `${serverUrl}/service/custom/auth/tag/organization/objectiveTimeBound/import`,
      getTimeBoundObjective: `${serverUrl}/service/custom/auth/tag/organization/objectiveTimeBoundFromId`,
      cloneObjective: `${serverUrl}/service/custom/auth/tag/organization/objectiveTimeBound/copy`,
      deleteObjective: (timeBoundObjectiveId) =>
        `${serverUrl}/service/custom/auth/tag/organization/objectiveTimeBound/${timeBoundObjectiveId}`,
      objectiveTree: `${serverUrl}/service/custom/auth/tag/organization/objectiveTimeBoundTree`,
      objectiveHierarchyIds: `${serverUrl}/service/custom/auth/tag/organization/hierarchy`,
      realignObjective: `${serverUrl}/service/custom/auth/tag/organization/objectiveTimeBound/realign`,
      parentLevelVisibleForDeleteRealign: (objectiveTimeBoundId) =>
        `${serverUrl}/service/custom/auth/tag/organization/objectiveTimeBound/${objectiveTimeBoundId}/krExists`,
      objectiveBehaviourSettings: `${serverUrl}/service/custom/auth/organization/settings/objective/behaviour`,
    },
    generateEmployeeDataFile: `${serverUrl}/service/custom/auth/employee/export/csv`,
    employeeCount: `${serverUrl}/service/custom/auth/employee/count`,
    notificationSettings: {
      channelDetailsList: `${serverUrl}/service/custom/auth/notificationSettings/channelDetailsList`,
      channelDetails: (uuid) => `${serverUrl}/service/custom/auth/notificationSettings/channelDetails/${uuid}`,
      eventMappingListForOrg: `${serverUrl}/service/custom/auth/notificationSettings/eventMappingListForOrg`,
      eventChannelMappingForOrg: `${serverUrl}/service/custom/auth/notificationSettings/eventChannelMappingForOrg`,
      setToDefault: `${serverUrl}/service/custom/auth/notificationSettings/setToDefault`,
      resetToDefault: (isMeshDefaultMapping) =>
        `${serverUrl}/service/custom/auth/notificationSettings/resetToDefault?isMeshDefaultMapping=${isMeshDefaultMapping}`,

      // employee APIs
      eventMappingListForEmp: `${serverUrl}/service/custom/auth/notificationSettings/eventMappingListForEmp`,
      channelDetailsForEmp: `${serverUrl}/service/custom/auth/notificationSettings/channelDetailsListForEmp`,
      channelDetailsForOrg: `${serverUrl}/service/custom/auth/notificationSettings/channelDetailsListForOrg`,
      eventChannelMappingForEmp: `${serverUrl}/service/custom/auth/notificationSettings/eventChannelMappingForEmp`,
      setToOrgDefaultForEmp: `${serverUrl}/service/custom/auth/notificationSettings/setToOrgDefaultForEmp`,
    },
    customDefinition: {
      definitions: `${serverUrl}/service/custom/auth/custom/customAccessDefinitions`,
      definition: `${serverUrl}/service/custom/auth/custom/customAccessDefinition`,
      definition2: (id) => `${serverUrl}/service/custom/auth/custom/customaccessdefinition/${id}`,
      employee: `${serverUrl}/service/custom/auth/custom/customAccessDefinition/employee`,
    },
    /* Access Controlled Bulk Upload APIs start here */
    bulkUploadObjectives: `${serverUrl}/service/custom/auth/bulkupload/objectives`,
    bulkUploadKeyResults: `${serverUrl}/service/custom/auth/bulkupload/keyresults`,
    bulkUploadOKR: `${serverUrl}/service/custom/auth/bulkupload/okr`,
    bulkUploadOKRSampleFile: `${serverUrl}/service/custom/auth/bulkupload/downloadSample`,
    /* Access Controlled Bulk Upload APIs end here */
    goalPreferences: {
      template: `${serverUrl}/service/custom/auth/goal-config-templates`,
      specificTemplate: (id) => `${serverUrl}/service/custom/auth/goal-config-templates/${id}`,
      selectedTemplate: `${serverUrl}/service/custom/auth/goal-config-templates/selected-template`,
    },
    goalConfigV1: {
      templates: `${serverUrl}/service/custom/auth/goal-config-templates`,
      templateById: (id) => `${serverUrl}/service/custom/auth/goal-config-templates/v1/${id}`,
      createTemplate: `${serverUrl}/service/custom/auth/goal-config-templates/v1`,
      validateAccessControlTable: `${serverUrl}/service/custom/auth/goal-config-templates/v1/validated-access-control-table`,
    },
    launchDeer: `${serverUrl}/service/custom/auth/organization/ldFeatureFlagAction`,
  },
  // auth
  auth: {
    userEmailState: `${serverUrl}/service/custom/auth/login/userEmailState`,
    userEmailRegister: `${serverUrl}/service/custom/auth/login/userEmailRegister`,
    validateEmail: `${serverUrl}/service/custom/auth/login/userEmailVerification`,
    updateEmployee: `${serverUrl}/service/custom/auth/employee`,
    employee: `${serverUrl}/service/custom/auth/employee`,
    singup: `${serverUrl}/service/custom/auth/login/signUp`,
    signin: `${serverUrl}/service/custom/auth/login/signIn`,
    loginType: `${serverUrl}/service/custom/auth/login/loginType`,
    photo: (employeeId) => `${serverUrl}/service/custom/auth/employee/${employeeId}/photo`,
    resetPassword: `${serverUrl}/service/custom/auth/login/changePassword`,
    userLoginEvent: (employeeId) => `${serverUrl}/service/custom/auth/login/userLoginEvent/${employeeId}`,
    forgotPassword: `${serverUrl}/service/custom/auth/login/forgotPassword`,
    forgotPasswordConfirmation: `${serverUrl}/service/custom/auth/login/forgotPasswordConfirmation`,
    auth0LoginInternal: `${serverUrl}/service/custom/auth/login/authorizeUrl/internal`,
    auth0Login: `${regionAgnosticServerUrl}/service/region/login/authorizeUrl/v1`,
    auth0Logout: `${serverUrl}/service/custom/auth/login/logout`,
    // auth0Authenticate: `${serverUrl}/service/custom/auth/login/callback/WEB`,
    auth0Authenticate: `${serverUrl}/service/custom/auth/login/authorizeCode`,
    resetAuth0Password: `${serverUrl}/service/custom/auth/login/resetPassword`,
    msTeamsConnectUserApi: `${serverUrl}/ms-teams/user-info/employee-id`,
  },
  // team
  team: {
    team: `${serverUrl}/service/custom/auth/team`,
    pendingInvites: (employeeId) => `${serverUrl}/service/custom/auth/employee/${employeeId}/pendingInvites`,
    inviteUser: `${serverUrl}/service/custom/auth/team/invite`,
    updateInvite: `${serverUrl}/service/custom/auth/team/invite/updateStatus`,
    updateEmployeeRole: (teamId, employeeId) =>
      `${serverUrl}/service/custom/auth/team/${teamId}/employee/${employeeId}`,
    changeDefaultTeam: (employeeId, teamId) =>
      `${serverUrl}/service/custom/auth/employee/${employeeId}/default/team/${teamId}`,
    removeEmployee: (employeeId, teamId) => `${serverUrl}/service/custom/auth/team/${teamId}/employee/${employeeId}`,
    update: (teamId) => `${serverUrl}/service/custom/auth/team/${teamId}`,
    delete: (teamId) => `${serverUrl}/service/custom/auth/team/${teamId}`,
  },
  tag: {
    organization: `${serverUrl}/service/custom/auth/tag/organization`,
  },
  subTasks: {
    // deprecated: to be removed from here and BE
    get: (taskId) => `${serverUrl}/service/custom/auth/tasks/${taskId}/subTask`,
    create: (taskId) => `${serverUrl}/service/custom/auth/tasks/${taskId}/subTask`,
    update: (taskId, subTaskId) => `${serverUrl}/service/custom/auth/tasks/${taskId}/subTask/${subTaskId}`,
    delete: (subTaskId, taskId) => `${serverUrl}/service/custom/auth/tasks/${taskId}/subTask/${subTaskId}`,
  },
  performanceReview: {
    activeReviewsCount: `${serverUrl}/service/custom/auth/review/summary/notification`,
    pendingReviews: `${serverUrl}/service/custom/auth/review/list`,
    summaryStats: `${serverUrl}/service/custom/auth/review/header/v1`,
    summaryStatsOld: `${serverUrl}/service/custom/auth/review/header`,
    reviewForm: `${serverUrl}/service/custom/auth/review/form/v2`,
    reviewFormPut: `${serverUrl}/service/custom/auth/review/form`,
    peerFeedback: `${serverUrl}/service/custom/auth/review/response`,
    submitReview: `${serverUrl}/service/custom/auth/review/submit`,
    reviewProviders: `${serverUrl}/service/custom/auth/review/providers`,
    acknowledge: `${serverUrl}/service/custom/auth/review/acknowledge/v1`,
    // nominatePeers: `${serverUrl}/service/custom/auth/review/peer/nominateList`,
    nominatePeers: `${serverUrl}/service/custom/auth/review/peer/nominateListV1`,
    nominatePeersDLM: `${serverUrl}/service/custom/auth/review/nominations/dlm`,
    nominatedPeers: `${serverUrl}/service/custom/auth/review/nominations`,
    withdrawNomination: `${serverUrl}/service/custom/auth/review/peer/withdrawNomination`,
    peerPool: `${serverUrl}/service/custom/auth/review/peer/pool`,
    peerPoolDetails: `${serverUrl}/service/custom/auth/review/peer/poolDetails`,
    peerAlerts: `${serverUrl}/service/custom/auth/review/peerAlert`,
    acceptNomination: `${serverUrl}/service/custom/auth/review/peer/accept`,
    acceptDLMNomination: `${serverUrl}/service/custom/auth/review/dlm/accept`,
    rejectNomination: `${serverUrl}/service/custom/auth/review/peer/decline`,
    rejectDLMNomination: `${serverUrl}/service/custom/auth/review/dlm/decline`,
    peerStatus: `${serverUrl}/service/custom/auth/review/peerStatus`,
    upwardStatus: `${serverUrl}/service/custom/auth/review/upwardStatus`,
    activeReviews: `${serverUrl}/service/custom/auth/review/summary/list/active`,
    archivedReviews: `${serverUrl}/service/custom/auth/review/summary/list/archived`,
    requestedReviews: `${serverUrl}/service/custom/auth/review/summary/list/requested`,
    teamReviews: `${serverUrl}/service/custom/auth/review/summary/list/team/v1`,
    continuousFeedback: {
      share: `${serverUrl}/service/custom/auth/feedback/share`,
      get: (feedbackId) => `${serverUrl}/service/custom/auth/feedback/${feedbackId}`,
      request: `${serverUrl}/service/custom/auth/feedback/request`,
      shareRequested: (feedbackId) => `${serverUrl}/service/custom/auth/feedback/${feedbackId}/share`,
      archive: (feedbackId) => `${serverUrl}/service/custom/auth/feedback/${feedbackId}/archive`,
      accept: (feedbackId) => `${serverUrl}/service/custom/auth/feedback/${feedbackId}/accept`,
      decline: (feedbackId) => `${serverUrl}/service/custom/auth/feedback/${feedbackId}/decline`,
      stats: `${serverUrl}/service/custom/auth/feedback/stats`,
      all: `${serverUrl}/service/custom/auth/review/summary/list/feedback`,
      allSharedFeedbacksV1: `${serverUrl}/service/custom/auth/feedback/shared`,
      allReceivedFeedbacksV1: `${serverUrl}/service/custom/auth/feedback/received`,
      feedbackTable: `${serverUrl}/service/custom/auth/feedback/feedbackTable`,
      receivedFeedbackTable: (employeeId) => `${serverUrl}/service/custom/auth/feedback/received/${employeeId}`,
      requestedFeedBack: (feedbackId) => `${serverUrl}/service/custom/auth/feedback/requested/${feedbackId}`,
      sharedOrReceivedFeedback: (postId) => `${serverUrl}/service/custom/auth/feedback/post/${postId}`,
      analyze: `${serverUrl}/service/custom/auth/post/analyze`,
      rephrase: `${serverUrl}/service/custom/auth/post/rephrase`,
      feedback: `${serverUrl}/service/custom/auth/mlGenerator/feedback`,
      deleteFeedback: (feedbackId) => `${serverUrl}/service/custom/auth/feedback/${feedbackId}`,
    },
    pastFeedbacks: `${serverUrl}/service/custom/auth/review/summary/list/profile/feedback`,
    documents: `${serverUrl}/service/custom/auth/review/documents`,
    faqs: `${serverUrl}/service/custom/auth/review/faqs`,
    timeline: `${serverUrl}/service/custom/auth/review/timeline`,
    timelineV1: `${serverUrl}/service/custom/auth/review/summary/list/timelines`,
    publish: `${serverUrl}/service/custom/auth/review/summary/publish`,
    unpublish: `${serverUrl}/service/custom/auth/review/summary/unpublish`,
    reviewGoals: `${serverUrl}/service/custom/auth/review/goals`,

    // rabbit APIS
    reviewResponseV1: `${serverUrl}/service/custom/auth/review/response/v1`,
    reviewFormV1: `${serverUrl}/service/custom/auth/review/form/v1`,
    reviewFormsSections: `${serverUrl}/service/custom/auth/review/summary/reviewFormSectionHeader`,
    reviewFormsList: `${serverUrl}/service/custom/auth/review/summary/reviewFormsInAgGridFormat`,
    reviewTableCSVReport: `${serverUrl}/service/custom/auth/review/summary/exportReviewForms`,
    manage: {
      tabularData: `${serverUrl}/service/custom/auth/analytics/leaders/review/tabular/v1`,
      filterData: `${serverUrl}/service/custom/auth/analytics/leaders/review/filteredValues`,
      calibrationHistory: (id) => `${serverUrl}/service/custom/auth/analytics/leaders/calibration/history/${id}`,
      providersList: `${serverUrl}/service/custom/auth/review/summary/providerslist`,
      calibrationRecompute: (employeeId) =>
        `${serverUrl}/service/custom/auth/analytics/leaders/review/recomputeRating/${employeeId}`,
      recalibrationSave: (employeeId) =>
        `${serverUrl}/service/custom/auth/analytics/leaders/review/recalibrate/v1/${employeeId}`,
      reopenReviewForm: `${serverUrl}/service/custom/auth/analytics/leaders/review/reopen/v1`,
      manageTableCSVReport: (type) =>
        `${serverUrl}/service/custom/auth/analytics/leaders/${type}/tabular/report/generate`,
      editableFieldsForCalibration: `${serverUrl}/service/custom/auth/admin/form/editableFieldsForCalibration`,
      forceSubmit: `${serverUrl}/service/custom/auth/analytics/leaders/review/forceSubmitReviewForm`,
      notify: `${serverUrl}/service/custom/auth/review/notify`,
      reviewHistory: `${serverUrl}/service/custom/auth/review/graph/reviewHistory`,
    },
    reviewManage: {
      panel: `${serverUrl}/service/custom/auth/review/calibration/panel`,
      recalibrate: `${serverUrl}/service/custom/auth/review/calibration/recalibrate`,
    },
    talentPlanning: {
      table: `${serverUrl}/service/custom/auth/calibration/table`,
      panel: `${serverUrl}/service/custom/auth/calibration/panel`,
      reviewHistory: `${serverUrl}/service/custom/auth/calibration/reviewHistory`,
      singleRowData: `${serverUrl}/service/custom/auth/calibration/table/row`,
    },
    insights: {
      tabularData: `${serverUrl}/service/custom/auth/analytics/leaders/reviewInsight/tabular/v1`,
      // prevalenceChart: `${serverUrl}/service/custom/auth/analytics/leaders/prevChart/prevalenceChart`,
      // insightsTableCSVReport: `${serverUrl}/service/custom/auth/analytics/leaders/reviewInsight/tabular/report/generate`,
    },
    reviewPeriodSummaryGoals: `${serverUrl}/service/custom/auth/review/reviewFormGoals`,
    performancePreview: `${serverUrl}/service/custom/auth/review/performancePreview`,
    timelineList: `${serverUrl}/service/custom/auth/review/weightage/timelineList`,
    okrWeightageList: `${serverUrl}/service/custom/auth/review/okrWeightageList`,
    updateReviewOkrsWeightage: `${serverUrl}/service/custom/auth/review/weightage`,
    textAnalysis: {
      analyzeText: `${serverUrl}/service/custom/auth/review/analyseFeedbackFormText`,
      formatFeedbackFormText: `${serverUrl}/service/custom/auth/review/formatFeedbackFormText`,
      customerFeedback: `${serverUrl}/service/custom/auth/mlGenerator/feedback`,
    },
    aIManagerSummary: {
      questionSummaryPoints: `${serverUrl}/service/custom/auth/review/managerSummaryPoints`,
      saveFeedback: `${serverUrl}/service/custom/auth/review/managerSummaryFeedback`,
    },
    competencyTable: `${serverUrl}/service/custom/auth/review/previewPerformanceCompetencyList`,
    newNav: {
      gridLineGraph: `${serverUrl}/service/custom/auth/review/graph/boxGridLineGraph`,
    },
  },
  plugins: {
    teamChannelMapping: {
      index: (thirdPartyType) => `${serverUrl}/service/plugins/${thirdPartyType}/teamChannelMapping`,
      show: (thirdPartyType, mappingId) =>
        `${serverUrl}/service/plugins/${thirdPartyType}/teamChannelMapping/${mappingId}`,
    },
    features: {
      index: (thirdPartyType) => `${serverUrl}/service/plugins/${thirdPartyType}/features`,
      show: (thirdPartyType, id) => `${serverUrl}/service/plugins/${thirdPartyType}/features/${id}`,
    },
    channel: (thirdPartyType) => `${serverUrl}/service/plugins/${thirdPartyType}/channels/search`,
    slack: {
      auth: `${serverUrl}/service/plugins/slack/signIn`,
      authDetails: `${serverUrl}/service/plugins/slack/auth`,
      applause: `${serverUrl}/service/plugins/slack/teamChannelMapping`,
    },
    jiraAuth: `https://marketplace.atlassian.com/apps/1224200/mesh-for-jira?hosting=cloud&tab=overview`,
    authorizationDetails: `${serverUrl}/service/custom/auth/authorizationDetails`,
    updateJiraAuthDetails: `${serverUrl}/service/custom/auth/authorizationDetails/connectToJira`,
    salesforce: {
      addSalesforce: 'https://login.salesforce.com/identity/app/AppInstallApprovalPage.apexp?app_id=0Ci5g000000Cc6G',
      authorize:
        'https://login.salesforce.com/services/oauth2/authorize?response_type=code&client_id=3MVG9fe4g9fhX0E5N7Lkz4hpn2IdNU96FYfHzzO_C211toWigyaPesyrCx3i9votiQ7c8D5mNTPQ4xdBhd1ws&redirect_uri=https://salesforce.mesh.ai/sf-callback',
      checkOrgStatus: `${serverUrl}/service/plugins/salesforce/settings/checkOrg`,
      addOrg: `${serverUrl}/service/plugins/salesforce/settings/addOrg`,
      callback: `${serverUrl}/service/plugins/salesforce/settings/callback`,
    },
    darwinboxAuthDetails: {
      authDetails: `${serverUrl}/service/plugins/darwinbox/authDetails`,
    },
    mergeHRISIntegration: {
      getLinkToken: `${serverUrl}/service/plugins/merge/link-token`,
      storePermanentAccountToken: `${serverUrl}/service/plugins/merge/save-token`,
      authDetails: `${serverUrl}/service/plugins/merge/auth`,
      deactivate: `${serverUrl}/service/plugins/merge/deactivate`,
    },
    finchHRISIntegration: {
      storePermanentAccountToken: `${serverUrl}/service/plugins/finch/save-token`,
      authDetails: `${serverUrl}/service/plugins/finch/auth`,
    },
    calendar: {
      authURL: `${serverUrl}/service/plugins/calendar/v2/authorizeUrl`,
      authDetailsv2: `${serverUrl}/service/plugins/calendar/v2`,
      authorizeCodev2: (calendarType) => `${serverUrl}/service/plugins/calendar/v2/authorizeCode/${calendarType}`,
      deactivate: `${serverUrl}/service/plugins/calendar/deactivate`,
      deactivateV2: `${serverUrl}/service/plugins/calendar/v2/deactivate`,
    },
  },

  extensions: {
    userid: `${serverUrl}/service/system/authenticate`,
  },
  reviews: {
    reviewCycle: {
      index: `${serverUrl}/service/custom/auth/admin/form/reviewCycle`,
      archival: `${serverUrl}/service/custom/auth/admin/form/archive`,
    },
    reviewCycleCreation: {
      reviewTypeAndDetails: `${serverUrl}/service/custom/auth/admin/reviewCycle/details`,

      reviewees: `${serverUrl}/service/custom/auth/admin/reviewCycle/reviewees`,
      viewsList: `${serverUrl}/service/custom/auth/admin/reviewCycle/views`,
      cohortEmployees: `${serverUrl}/service/custom/auth/admin/reviewCycle/view/employees`,
      overlappingViewEmployees: `${serverUrl}/service/custom/auth/admin/reviewCycle/checkOverlappingViewsEmployees`,

      reviewers: `${serverUrl}/service/custom/auth/admin/reviewCycle/reviewers`,
      elements: `${serverUrl}/service/custom/auth/admin/reviewCycle/elements`,
      formFilling: `${serverUrl}/service/custom/auth/admin/reviewCycle/formFilling`,

      templateMetadata: `${serverUrl}/service/custom/auth/admin/reviewCycle/templateMetadata`,
      templateList: `${serverUrl}/service/custom/auth/admin/form/template`,

      calibrationSettings: `${serverUrl}/service/custom/auth/admin/reviewCycle/calibrationSettings`,
      timelinesSettings: `${serverUrl}/service/custom/auth/admin/reviewCycle/reviewTimeline`,

      shareResults: `${serverUrl}/service/custom/auth/admin/reviewCycle/shareResults`,

      reviews: `${serverUrl}/service/custom/auth/admin/reviewCycle/reviews`,
      reviewsList: `${serverUrl}/service/custom/auth/admin/reviewCycle/list`,
      reviewsClone: `${serverUrl}/service/custom/auth/admin/reviewCycle/reviews/clone`,
      reviewsArchive: `${serverUrl}/service/custom/auth/admin/reviewCycle/archive`,

      validation: `${serverUrl}/service/custom/auth/admin/reviewCycle/validateReviewCycle`,
    },

    reviewers: `${serverUrl}/service/custom/auth/admin/form/reviewers`,
    reviewFormV1: `${serverUrl}/service/custom/auth/admin/reviewCycle/create`,
    question: {
      index: `${serverUrl}/service/custom/auth/admin/form/question`,
      show: (questionId) => `${serverUrl}/service/custom/auth/admin/form/question/${questionId}`,
    },
    questionsWithCustomDescription: `${serverUrl}/service/custom/auth/admin/form/questionsWithCustomDescription`,
    ratingDetails: `${serverUrl}/service/custom/auth/review/ratingDetails`,
    ratingDetailsFormFilling: `${serverUrl}/service/custom/auth/review/computeCurrentRatings`,
    ratingDetailsSubmittedForm: `${serverUrl}/service/custom/auth/review/ratingDetailsForSubmittedForm`,
    ratingDetailsIndividualResult: `${serverUrl}/service/custom/auth/review/ratingDetailsForIndividualSubmittedForm`,
  },
  templates: {
    review: {
      activeTemplates: `${serverUrl}/service/custom/auth/review/template/list/active`,
      sampleTemplates: `${serverUrl}/service/custom/auth/review/template/list/sample`,
      archivedTemplates: `${serverUrl}/service/custom/auth/review/template/list/archived`,
      templateStructure: `${serverUrl}/service/custom/auth/review/template/structure`,
      templateSection: `${serverUrl}/service/custom/auth/review/template/section`,
      templateReviewSection: `${serverUrl}/service/custom/auth/review/template/reviewSection`,
      archiveTemplate: `${serverUrl}/service/custom/auth/review/template/archive`,
      duplicateTemplate: `${serverUrl}/service/custom/auth/review/template/duplicate`,
    },
    checkin: {
      activeTemplates: `${serverUrl}/service/custom/auth/oneOnOne/template/list/active`,
      sampleTemplates: `${serverUrl}/service/custom/auth/oneOnOne/template/list/sample`,
      archivedTemplates: `${serverUrl}/service/custom/auth/oneOnOne/template/list/archived`,
      templateStructure: `${serverUrl}/service/custom/auth/oneOnOne/structure`,
      questionList: `${serverUrl}/service/custom/auth/oneOnOne/question`,
      updateTemplate: (id) => `${serverUrl}/service/custom/auth/oneOnOne/template/${id}`,
      archiveTemplate: `${serverUrl}/service/custom/auth/oneOnOne/template/archive`,
      duplicateTemplate: `${serverUrl}/service/custom/auth/oneOnOne/template/duplicate`,
    },
    engagement: {
      activeTemplates: `${serverUrl}/service/custom/auth/engagement/template/list/active`,
      sampleTemplates: `${serverUrl}/service/custom/auth/engagement/template/list/sample`,
      archivedTemplates: `${serverUrl}/service/custom/auth/engagement/template/list/archived`,
      archiveTemplate: `${serverUrl}/service/custom/auth/engagement/template/archive`,
      duplicateTemplate: `${serverUrl}/service/custom/auth/engagement/template/duplicate`,
      templateStructure: `${serverUrl}/service/custom/auth/engagement/template`,
    },
  },
  goalTracking: {
    metrics: `${serverUrl}/service/plugins/metrics/getMetric`,
    metricName: `${serverUrl}/service/plugins/metrics/getMetricName`,
    unlink: `${serverUrl}/service/plugins/metrics/unlink`,
    link: `${serverUrl}/service/plugins/metrics/link`,
    unlinkV1: `${serverUrl}/service/plugins/metrics/unlink/v1`,
    linkV1: `${serverUrl}/service/plugins/metrics/link/v1`,
    queryString: `${serverUrl}/service/plugins/metrics/getQueryString`,
  },
  checkIn: {
    talkingPointQuestions: (orgId) => `${serverUrl}/service/custom/auth/organization/${orgId}/checkIn/talkingPoints`,
    // recordCheckIn is deprecated now. TODO: remove by end of june.
    recordCheckIn: (checkInId) => `${serverUrl}/service/custom/auth/checkIn/${checkInId}/record`,
    timeline: (checkInId) => `${serverUrl}/service/custom/auth/oneOnOne/${checkInId}/timeline`,
    updateDueDate: (checkInInstanceId) => `${serverUrl}/service/custom/auth/checkIn/${checkInInstanceId}/updateDueDate`,
    timelineStatus: (checkInId) => `${serverUrl}/service/custom/auth/oneOnOne/${checkInId}/update/timelineStatus`,
    recurrenceDetails: `${serverUrl}/service/custom/auth/checkIn/recurrenceTaskDetails`,
    generateCalendar: `${serverUrl}/service/custom/auth/checkIn/generate-calendar`,
    preferences: `${serverUrl}/service/custom/auth/checkIn/settings/preferences`,
    createOneOnOnes: `${serverUrl}/service/custom/auth/checkIn/create`,
    talkingPointComments: `${serverUrl}/service/custom/auth/checkIn/activity/comments`,
    archiveOneOnOne: `${serverUrl}/service/custom/auth/checkIn/archive`,
    unarchiveOneOnOne: `${serverUrl}/service/custom/auth/checkIn/unarchive`,
    fetchGoalRecurrence: `${serverUrl}/service/custom/auth/organization/settings/goalCheckinReminder`,
    updateGoalRecurrence: `${serverUrl}/service/custom/auth/organization/settings/goalReminder`,
  },
  msTeams: {
    saveWebhookUrl: `${serverUrl}/service/plugins/msTeams/teamChannelMapping`,
    removeWebhookUrl: (uuid) => `${serverUrl}/service/plugins/msTeams/teamChannelMapping/${uuid}`,
    getChannelMapping: `${serverUrl}/service/plugins/msTeams/teamChannelMapping`,
  },
  internalTools: {
    saveCampaign: `${serverUrl}/service/custom/auth/admin/pinpoint/update/customCampaign`,
  },
  engagement: {
    survey: `${serverUrl}/service/custom/auth/engagement/form`,
    submitSurvey: `${serverUrl}/service/custom/auth/engagement/submit`,
    activeSurveys: `${serverUrl}/service/custom/auth/engagement/list/active`,
    archivedSurveys: `${serverUrl}/service/custom/auth/engagement/list/archived`,
    surveyResult: `${serverUrl}/service/custom/auth/engagement/result`,
    kickstart: {
      index: `${serverUrl}/service/custom/auth/engagement/kickstart/form/list`,
      cohortSelection: {
        // getCohort: `${serverUrl}/service/custom/auth/engagement/kickstart/form/participants`,
        getCohortV1: `${serverUrl}/service/custom/auth/engagement/kickstart/form/participants/v1`,
        putCohort: `${serverUrl}/service/custom/auth/engagement/kickstart/form/participants/cohort`,
        uploadEmployeesCSV: `${serverUrl}/service/custom/auth/engagement/kickstart/form/participants/upload`,
      },
    },
    shareExperience: `${serverUrl}/service/custom/auth/engagement/share`,
    getSharedExperience: `${serverUrl}/service/custom/auth/engagement/getSharedExperience`,
    sharedExperienceList: `${serverUrl}/service/custom/auth/engagement/list/shared`,

    createSurvey: `${serverUrl}/service/custom/auth/engagement/kickstart/form/create`,
    drivers: `${serverUrl}/service/custom/auth/tag/organization`,
    getSurvey: `${serverUrl}/service/custom/auth/engagement/kickstart/form`,

    surveyTemplateList: `${serverUrl}/service/custom/auth/engagement/kickstart/form/templateList`,
    selectedTemplate: `${serverUrl}/service/custom/auth/engagement/kickstart/form/template`,

    settings: `${serverUrl}/service/custom/auth/engagement/kickstart/form/setting`,

    reminders: `${serverUrl}/service/custom/auth/engagement/kickstart/form/reminders`,

    validation: `${serverUrl}/service/custom/auth/engagement/kickstart/form/validation`,

    startSurvey: `${serverUrl}/service/custom/auth/engagement/kickstart/form/start`,
  },
  nudge: {
    feedCards: `${serverUrl}/service/custom/auth/nudge/feed`,
    shortSurvey: `${serverUrl}/service/custom/auth/nudge/survey`,
  },
  oneOnOne: {
    stats: `${serverUrl}/service/custom/auth/oneOnOne/stats`,
    list: `${serverUrl}/service/custom/auth/oneOnOne/list`,
    instanceList: (parentId) => `${serverUrl}/service/custom/auth/oneOnOne/${parentId}/instanceList`,
    guide: `${serverUrl}/service/custom/auth/oneOnOne/guide`,
    savePreference: (recurrentTaskId) => `${serverUrl}/service/custom/auth/checkIn/${recurrentTaskId}/updateTemplate`,
    comments: `${serverUrl}/service/custom/auth/checkIn/activity/comments`,
    defaultTemplate: (orgId) => `${serverUrl}/service/custom/auth/organization/${orgId}/oneOnOne/template`,
    feedback: `${serverUrl}/service/custom/auth/oneOnOne/feedback`,
    actionItems: {
      get: (oneOnOneInstanceId) => `${serverUrl}/service/custom/auth/checkIn/${oneOnOneInstanceId}/actionItem`,
      createOrUpdate: `${serverUrl}/service/custom/auth/checkIn/actionItem`,
    },
    chart: `${serverUrl}/service/custom/auth/analytics/leaders/oneOnOne/charts`,
  },
  growth: {
    admin: {
      jobFunctionList: `${serverUrl}/service/custom/auth/growth/function/list`,
      jobFunction: `${serverUrl}/service/custom/auth/growth/function`,
      jobFunctionLevel: `${serverUrl}/service/custom/auth/growth/function/grade`,
      moveJobFunctionLevel: `${serverUrl}/service/custom/auth/growth/function/grade/move`,
      jobFunctionDimension: `${serverUrl}/service/custom/auth/growth/function/dimension`,
      moveJobFunctionDimension: `${serverUrl}/service/custom/auth/growth/function/dimension/move`,
      jobFunctionResponsibility: `${serverUrl}/service/custom/auth/growth/function/responsibility`,
    },
    oneOnOne: {
      index: (employeeId) => `${serverUrl}/service/custom/auth/levelup/${employeeId}`,
      pickRole: (employeeId) => `${serverUrl}/service/custom/auth/levelup/${employeeId}/pickrole`,
      comptencyPeriod: (employeeId) => `${serverUrl}/service/custom/auth/levelup/${employeeId}/due_date`,
      competencyStatus: (employeeId) => `${serverUrl}/service/custom/auth/levelup/${employeeId}/progress`,
      options: (employeeId) => `${serverUrl}/service/custom/auth/levelup/${employeeId}/options`,
      details: (employeeId) => `${serverUrl}/service/custom/auth/levelup/${employeeId}/details`,
      // comment apis
      comment: {
        create: (employeeId) => `${serverUrl}/service/custom/auth/levelup/${employeeId}/comment`,
        update: (employeeId, commentId) =>
          `${serverUrl}/service/custom/auth/levelup/${employeeId}/comment/${commentId}`,
      },
      getCompetenciesForRole: (employeeId) =>
        `${serverUrl}/service/custom/auth/levelup/${employeeId}/role_competencies`,
      levelUpTasks: (employeeId) => `${serverUrl}/service/custom/auth/levelup/${employeeId}/task`,
    },
  },
  competency: {
    competencySetting: `${serverUrl}/service/custom/auth/competency/setting`,
    competencyFunctionList: `${serverUrl}/service/custom/auth/competency/functions`,
    competencySummary: `${serverUrl}/service/custom/auth/competency/summary`,
    competencyDescription: `${serverUrl}/service/custom/auth/competency/function/description`,
    updateCompetencyDescription: (id) => `${serverUrl}/service/custom/auth/competency/${id}/description`,
    saveCompetency: `${serverUrl}/service/custom/auth/competency/save`,
    updateCompetency: (uuid) => `${serverUrl}/service/custom/auth/competency/${uuid}/update`,
    disableCompetency: `${serverUrl}/service/custom/auth/competency/disable/competency`,
    enableCompetency: `${serverUrl}/service/custom/auth/competency/enable/competency`,
    competencyScore: `${serverUrl}/service/custom/auth/competencyScore/details`,
    competencyUpdatedScore: `${serverUrl}/service/custom/auth/competencyScore/score`,
    checkin: `${serverUrl}/service/custom/auth/competencyScore/checkin`,
    comments: `${serverUrl}/service/custom/auth/competencyScore/comments`,
    updateComment: (commentId) => `${serverUrl}/service/custom/auth/competencyScore/comment/${commentId}`,
    task: `${serverUrl}/service/custom/auth/competencyScore/initiatives`,
    createTask: `${serverUrl}/service/custom/auth/competencyScore/initiative`,
    updateTask: (taskId) => `${serverUrl}/service/custom/auth/competencyScore/initiative/${taskId}`,
    labelConfig: `${serverUrl}/service/custom/auth/competencyScore/labelConfig`,
    myCompetencies: `${serverUrl}/service/custom/auth/competencyScore/tabular`,
    competencyScoreAlert: `${serverUrl}/service/custom/auth/admin/form/competencyScoreAlert`,
    spiderChart: `${serverUrl}/service/custom/auth/competencyScore/spider`,
  },
  meshRecommendations: `${serverUrl}/service/custom/auth/recommendations`,
  // onboardingTooltips: {
  //   getOnboardingTooltips: `${serverUrl}/service/custom/auth/tooltips`,
  // },
  levelUp: {
    competencyData: (employeeId) => `${serverUrl}/service/custom/auth/levelup/${employeeId}/competency_data`,
    addToAgenda: (employeeId) => `${serverUrl}/service/custom/auth/levelup/${employeeId}/agenda`,
    reporteeData: (employeeId) => `${serverUrl}/service/custom/auth/levelup/${employeeId}/reportee_data`,
    addCompetencyToAgenda: (employeeId) => `${serverUrl}/service/custom/auth/levelup/${employeeId}/addToAgenda`,
  },
  sidebarNav: {
    cohortList: `${serverUrl}/service/custom/auth/cohort`,
    cohortListV1: `${serverUrl}/service/custom/auth/cohort/v1`,
  },
  rabbitGoals: {
    childFunctionIds: (functionTreeId) =>
      `${serverUrl}/service/custom/auth/functionTree/${functionTreeId}/childFunctionIds`,
    departmentHierarchy: `${serverUrl}/service/custom/auth/cohort/departmentHierarchy`,
    index: (goalId) => `${serverUrl}/service/custom/auth/goals/${goalId}`,
    progress: (goalId) => `${serverUrl}/service/custom/auth/goals/${goalId}/progress`,
    bulkCheckIn: `${serverUrl}/service/custom/auth/bulkupload/bulkCheckIn`,
    createOKR: `${serverUrl}/service/custom/auth/goals/save`,
    deleteOKR: (okrId) => `${serverUrl}/service/custom/auth/goals/${okrId}`,
    cloneOKR: `${serverUrl}/service/custom/auth/goals/copy`,
    realignView: `${serverUrl}/service/custom/auth/goals/realignView`,
    realign: `${serverUrl}/service/custom/auth/goals/realign`,
    topHierarchy: (okrId) => `${serverUrl}/service/custom/auth/goals/${okrId}/topHierarchy`,
    childGoal: `${serverUrl}/service/custom/auth/goals/childGoal`,
    graph: `${serverUrl}/service/custom/auth/analytics/leaders/goal/graph`,
    getCustomTagsOrganization: `${serverUrl}/service/custom/auth/tags`,
    createCustomTagsOrganization: `${serverUrl}/service/custom/auth/tags`,
    attachTagToOKR: (okrId) => `${serverUrl}/service/custom/auth/tags/${okrId}/attach`,
    modifyTagToOKR: (okrId) => `${serverUrl}/service/custom/auth/tags/modifyTags/${okrId}`,
    getOKRTags: (okrId) => `${serverUrl}/service/custom/auth/tags/listTaskTags/${okrId}`,
    deleteOrganizationTag: (tagId) => `${serverUrl}/service/custom/auth/tags/${tagId}`,
    generator: `${serverUrl}/service/custom/auth/goals/generateTitle`,
    generatorTracking: `${serverUrl}/service/custom/auth/goals/generateTracking`,
    generateRecommendation: `${serverUrl}/service/custom/auth/goals/generateRecommendation`,
    feedback: `${serverUrl}/service/custom/auth/mlGenerator/feedback`,
    /* Insights apis */
    generateReportIndividualInsights: `${serverUrl}/service/custom/auth/goals/individual-insights-reports`,
    reportGenerationStatusIndividualInsights: `${serverUrl}/service/custom/auth/goals/individual-insights-reports/status`,
    downloadReportIndividualInsights: (reportId) =>
      `${serverUrl}/service/custom/auth/goals/individual-insights-reports/${reportId}`,

    generateReportObjectiveInsights: `${serverUrl}/service/custom/auth/goals/objective-insights-reports`,
    reportGenerationStatusObjectiveInsights: `${serverUrl}/service/custom/auth/goals/objective-insights-reports/status`,
    downloadReportObjectiveInsights: (reportId) =>
      `${serverUrl}/service/custom/auth/goals/objective-insights-reports/${reportId}`,
    treeView: `${serverUrl}/service/custom/auth/analytics/leaders/goal/treeView`,
    refreshMetric: (okrId) => `${serverUrl}/service/custom/auth/goals/refreshMetric/${okrId}`,
    getArchivedOkrs: `${serverUrl}/service/custom/auth/goals/archived`,
    unarchiveOkr: (okrId) => `${serverUrl}/service/custom/auth/goals/unarchive/${okrId}`,
  },
  oneOnOneRevamped: {
    instancePage: (masterId) => `${serverUrl}/service/custom/auth/ooo/${masterId}`,
    instanceDetailsFromMasterId: (masterId) => `${serverUrl}/service/custom/auth/ooo/${masterId}/latestInstance`,
    updateInstancePage: (masterId) => `${serverUrl}/service/custom/auth/ooo/${masterId}/update`,
    agenda: (masterId) => `${serverUrl}/service/custom/auth/ooo/${masterId}/agenda`,
    updateCompletion: (masterId) => `${serverUrl}/service/custom/auth/ooo/${masterId}/updateCompletion`,
    reorderAgenda: (masterId) => `${serverUrl}/service/custom/auth/ooo/${masterId}/reorderAgenda`,
    actionItems: (masterId) => `${serverUrl}/service/custom/auth/ooo/${masterId}/actionItems`,
    actionItem: (masterId) => `${serverUrl}/service/custom/auth/ooo/${masterId}/actionItem`,
    rating: (masterId) => `${serverUrl}/service/custom/auth/ooo/${masterId}/rating`,
    notes: (masterId) => `${serverUrl}/service/custom/auth/ooo/${masterId}/instanceNotes`,
    agendaNotes: (masterId) => `${serverUrl}/service/custom/auth/ooo/${masterId}/agendaNotes`,
    cancelInstance: (masterId) => `${serverUrl}/service/custom/auth/ooo/${masterId}/cancelInstance`,
    archiveInstance: (masterId) => `${serverUrl}/service/custom/auth/ooo/${masterId}/archive`,
    activeArchiveCount: `${serverUrl}/service/custom/auth/ooo/stats`,
    landingPage: `${serverUrl}/service/custom/auth/ooo/list`,
    pastOneOnOne: (masterId) => `${serverUrl}/service/custom/auth/ooo/${masterId}/instances`,
    relation: `${serverUrl}/service/custom/auth/ooo/relation`,
    create: `${serverUrl}/service/custom/auth/ooo`,
    settings: {
      getSettings: `${serverUrl}/service/custom/auth/ooo/settings`,
      putSettings: `${serverUrl}/service/custom/auth/ooo/settings`,
      regularTemplateAgenda: `${serverUrl}/service/custom/auth/ooo/regularTemplateAgenda/list`,
      eventTemplateAgenda: `${serverUrl}/service/custom/auth/ooo/eventTemplateAgenda/list`,
      createUpdateTemplateAgenda: `${serverUrl}/service/custom/auth/ooo/templateAgenda`,
      templateAgendaCount: `${serverUrl}/service/custom/auth/ooo/templateAgenda/count`,
      deleteTemplateAgenda: (id) => `${serverUrl}/service/custom/auth/ooo/templateAgenda/${id}`,
      deleteAgendaCondition: `${serverUrl}/service/custom/auth/ooo/agendaCondition`,
    },
    insights: {
      tabular: `${serverUrl}/service/custom/auth/analytics/leaders/oneOnOneV2/tabular`,
      graph: `${serverUrl}/service/custom/auth/ooo/insights/graph`,
    },
    agendaLibrary: (masterId) => `${serverUrl}/service/custom/auth/ooo/${masterId}/library/agenda`,
    addAgendaFromLibrary: (masterId) => `${serverUrl}/service/custom/auth/ooo/${masterId}/libraryAgenda`,
    agendaLibraryForSettings: `${serverUrl}/service/custom/auth/ooo/library/agenda`,
    getExistingOneOnOneList: `${serverUrl}/service/custom/auth/ooo/existingList`,
    agendaRecommendations: (masterId) => `${serverUrl}/service/custom/auth/ooo/${masterId}/agendaRecommendations`,
    completeInstance: (masterId) => `${serverUrl}/service/custom/auth/ooo/${masterId}/forceStatusChange`,
    markAsComplete: (masterId) => `${serverUrl}/service/custom/auth/ooo/${masterId}/completeInstance`,
    botRecommendations: (masterId) => `${serverUrl}/service/custom/auth/ooo/${masterId}/aiGeneratedData`,
    deleteAiGeneratedValue: (masterId) => `${serverUrl}/service/custom/auth/ooo/${masterId}/aiGeneratedData/isUsed`,
  },
  personaInsights: {
    generateInsightsUrl: `${serverUrl}/service/custom/auth/generateInsightsUrl/personaInsight/list`,
    checkInUserTrend: `${serverUrl}/service/custom/auth/generateInsightsUrl/personaInsight/f9IGtffaFyBpoIKWXlyTg/T_uBeTXZP5/checkinUserTrend/table`,
    checkInBehaviorTrend: `${serverUrl}/service/custom/auth/generateInsightsUrl/personaInsight/f9IGtffaFyBpoIKWXlyTg/NHbvAjwGsM/checkinBehaviourTrend/table`,
    checkInQuality: `${serverUrl}/service/custom/auth/generateInsightsUrl/personaInsight/f9IGtffaFyBpoIKWXlyTg/Zkhcu-BqXh/checkinQuality/table`,
    networkEngagementTrend: `${serverUrl}/service/custom/auth/generateInsightsUrl/personaInsight/f9IGtffaFyBpoIKWXlyTg/y07vseTRXu/networkGraph/table`,
    checkInUserTrendTable: `${serverUrl}/service/custom/auth/generateInsightsUrl/personaInsight/f9IGtffaFyBpoIKWXlyTg/pnUG6_JHgx/checkinUserTrend/raw`,
  },
  embeddedAnalytics: {
    connect: `${serverUrl}/service/custom/auth/visier`,
  },
  views: {
    cohort: {
      values: `${serverUrl}/service/custom/auth/cohort/validKeyValue`,
      save: `${serverUrl}/service/custom/auth/cohort`,
      baseCohorts: `${serverUrl}/service/custom/auth/cohort/baseCohorts`,
      getAll: `${serverUrl}/service/custom/auth/cohort/v1`,
      delete: `${serverUrl}/service/custom/auth/cohort/delete`,
      getById: `${serverUrl}/service/custom/auth/cohort`,
      edit: `${serverUrl}/service/custom/auth/cohort`,
    },
    cohortList: `${serverUrl}/service/custom/auth/cohort/list`,
    employees: `${serverUrl}/service/custom/auth/cohort/employees`,
    parseEmployeeCsv: `${serverUrl}/service/custom/auth/cohort/getEmployeesFromEmailCSV`,
    getFunctionTree: `${serverUrl}/service/custom/auth/functionTree/getFunctionTreeForBaseCohort`,
    getFilteredEmployeeCount: `${serverUrl}/service/custom/auth/cohort/filteredEmployeeCount`,
    getManagerList: `${serverUrl}/service/custom/auth/cohort/manager/list/v1`,
  },
};
