import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { OKR_TYPES } from 'constants/ProjectConstants';
import { getQueryParams, pushBackFeedbackParams } from 'constants/helper';

const initialState = {
  // isOpen: false,
  selectedObjective: null,
  selectedTimeBoundPeriod: null,
  isAlignmentAtParentLevel: false,
  onSave: () => {},
  onCancel: () => {},
  onObjectiveCreation: () => {},
  onKeyResultCreation: () => {},
  okrPanelType: null,
  ownerEmployee: null,
  parentMeasure: null,
  parentMeasureType: null,
  parentProgressType: null,
  newOKRDetail: null,
  parentPost: null,
};

export const createObjectivePanelSlice = createSlice({
  name: 'createObjectivePanel',
  initialState,
  reducers: {
    openCreateObjectivePanel: (state, action) => {
      // state.isOpen = true;
      state.selectedObjective = action.payload.selectedObjective;
      state.selectedTimeBoundPeriod = action.payload.selectedTimeBoundPeriod;
      state.isAlignmentAtParentLevel = action.payload?.isAlignmentAtParentLevel;
      state.onSave = action.payload?.onSave;
      state.onCancel = action.payload?.onCancel;
      state.onObjectiveCreation = action.payload?.onObjectiveCreation;
      state.onKeyResultCreation = action.payload?.onKeyResultCreation;
      // state.okrPanelType = action.payload?.okrPanelType ?? OKR_TYPES.OBJECTIVE;
      state.ownerEmployee = action.payload?.ownerEmployee ?? null;
      state.parentMeasure = action.payload?.parentMeasure ?? null;
      state.parentMeasureType = action.payload?.parentMeasureType ?? null;
      state.parentProgressType = action.payload?.parentProgressType ?? null;
      state.parentPost = action.payload?.parentPost ?? null;
    },
    closeCreateObjectivePanel: (state, action) => {
      // state.isOpen = false;
      state.selectedObjective = null;
      state.selectedTimeBoundPeriod = null;
      state.isAlignmentAtParentLevel = false;
      state.onSave = () => {};
      state.onCancel = () => {};
      state.onObjectiveCreation = () => {};
      state.onKeyResultCreation = () => {};
      state.okrPanelType = null;
      state.ownerEmployee = null;
      state.parentMeasure = null;
      state.parentMeasureType = null;
      state.parentProgressType = null;
      state.parentPost = null;
      state.newOKRDetail = null;
    },
    // that same action handles set and reset
    saveNewlyCreatedOkr: (state, action) => {
      state.newOKRDetail = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { openCreateObjectivePanel, closeCreateObjectivePanel, saveNewlyCreatedOkr } =
  createObjectivePanelSlice.actions;

export const useCreateObjectivePanel = () => {
  const {
    selectedObjective,
    selectedTimeBoundPeriod,
    isAlignmentAtParentLevel,
    onSave,
    onCancel,
    onObjectiveCreation,
    onKeyResultCreation,
    // okrPanelType,
    ownerEmployee,
    parentMeasure,
    parentMeasureType,
    parentProgressType,
    parentPost,
    newOKRDetail,
  } = useSelector((state) => state.createObjectivePanel);

  const location = useLocation();
  const { search } = location ?? {};

  const { createOkr, okrPanelType, ownerId, parentId } = getQueryParams(search);

  const isOpen = createOkr === 'true';
  const okrPanelTypeFromQuery = okrPanelType ?? OKR_TYPES.OBJECTIVE;

  return {
    isOpen,
    selectedObjective,
    selectedTimeBoundPeriod,
    isAlignmentAtParentLevel,
    onSave,
    onCancel,
    onObjectiveCreation,
    onKeyResultCreation,
    okrPanelType: okrPanelTypeFromQuery,
    ownerEmployee,
    parentMeasure,
    parentMeasureType,
    parentProgressType,
    parentPost,
    newOKRDetail,
    ownerId,
    parentId,
  };
};

export const openCreateOKRPanel =
  ({ history, location, okrPanelType, ...rest }) =>
  (dispatch) => {
    dispatch(openCreateObjectivePanel({ okrPanelType, ...rest }));

    const currentSearchParams = new URLSearchParams(location.search);
    currentSearchParams.set('createOkr', true);
    currentSearchParams.set('okrPanelType', okrPanelType ?? OKR_TYPES.OBJECTIVE);
    if (history) {
      history.push({
        search: currentSearchParams.toString(),
      });
    }
  };

export const closeCreateOKRPanel =
  ({ history, location }) =>
  (dispatch) => {
    dispatch(closeCreateObjectivePanel());
    const currentSearchParams = new URLSearchParams(location.search);
    currentSearchParams.delete('createOkr');
    currentSearchParams.delete('okrPanelType');

    // pushing to history
    // REF: don't use history.goBack() https://github.com/remix-run/react-router/issues/6512
    if (history) {
      history.push({
        search: currentSearchParams.toString(),
      });
    }
  };

export default createObjectivePanelSlice.reducer;
